.cut {
  align-items: center;
  display: flex;
  font-size: 0.75rem;
  font-weight: bold;
  justify-content: center;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.cut img {
  margin-left: 0.5rem;
  vertical-align: middle;
  width: 4rem;
}
