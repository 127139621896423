.form {
  margin-top: 2rem;
}

.field {
  border: 1px solid var(--c-gray-300);
  border-radius: var(--rounded);
  display: block;
  padding: 0.75rem;
  width: 100%;
}

.field-set {
  border: 1px solid var(--c-gray-300);
  border-radius: var(--rounded);
  margin: 1rem 0 0;
  padding: 0.75rem;
}

.field + .field,
.field + .button,
.field + .field-set {
  margin-top: 1rem;
}

.radio {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.radio__input {
  cursor: pointer;
  display: inline-block;
  opacity: 0;
  position: fixed;
}

.radio__button {
  background: var(--c-gray-300);
  border-radius: 50%;
  border: 1px solid var(--c-gray-300);
  display: inline-block;
  flex: none;
  height: 1.25rem;
  margin-right: 0.5rem;
  width: 1.25rem;
}

.radio--checked .radio__button {
  background: var(--c-primary-300);
}

.textarea {
  background: var(--c-gray-100);
  border: none;
  padding: 0.75rem;
  resize: none;
  width: 100%;
}

.textarea--expand {
  height: 100%;
}
