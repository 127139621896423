.deck {
  cursor: pointer;
  display: block;
  margin-bottom: 2rem;
  -webkit-tap-highlight-color: transparent;
}

.deck__body {
  display: block;
  margin: 0 auto;
  position: relative;
  width: var(--playcard-width);
}

.deck__body img {
  display: block;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16)) drop-shadow(0 3px 6px rgba(0, 0, 0, 0.23));
  width: 100%;
}

.deck__name {
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.06rem;
  padding: 0.5rem 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 80%;
  width: 100%;
}

.info {
  background: white;
  border-radius: 4px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  padding: 4rem 2rem;
  position: absolute;
  right: 0;
  top: 0;
}

.info__title {
  font-weight: bold;
  text-transform: uppercase;
}
