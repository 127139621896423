@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');

* {
  box-sizing: border-box;
}

html, body {
  color: #333333;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

#root {
  background: var(--c-gray-100);
  min-height: 100%;
}

:root {
  --playcard-width: 280px;
  --playcard-height: 400px;

  --rounded: 0.25rem;

  --card-1: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --card-2: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  --card-3: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  --card-4: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  --card-5: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

  --c-primary-500: #b3b3b3;
  --c-primary-700: #898989;

  --c-gray-100: #f9f9f9;
  --c-gray-200: #ededed;
  --c-gray-300: #e1e1e1;
  --c-gray-400: #d3d3d3;
  --c-gray-500: #b3b3b3;
  --c-gray-600: #a0a0a0;
  --c-gray-700: #898989;
  --c-gray-800: #6c6c6c;
  --c-gray-900: #3f3f3f;
}
