.playcard {
  background: white;
  box-shadow: var(--card-1);
  border-radius: var(--rounded);
  height: var(--playcard-height);
  padding: 3.5rem 1.5rem 4rem;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: var(--playcard-width);
}

.playcard--clickable {
  cursor: pointer;
}

.playcard__deck {
  font-size: 0.75rem;
  font-weight: 900;
  letter-spacing: 0.06rem;
  padding: 0.5rem 1rem;
  position: absolute;
  right: 1rem;
  text-transform: uppercase;
  top: 0;
}

.playcard__body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.playcard__prompt {
  flex: 1 1 50%;
  margin-bottom: 1rem;
}

.playcard__prompt:first-child {
  margin-bottom: 1rem;
}

.playcard__title {
  color: #a2a2a2;
  letter-spacing: 0.1rem;
  font-size: 0.75rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.playcard__question {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.3;
}

.playcard__hint {
  color: #828282;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.playcard__footer {
  bottom: 1rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 66.666%;
}
