.icon {
  background: none;
  border: none;
  display: block;
  flex: none;
  height: 1.5rem;
  opacity: 0.55;
  padding: 0;
  width: 1.5rem;
}

.icon:focus {
  transform: translateY(-1px);
  outline: none;
}

button.icon {
  cursor: pointer;
}

.icon img {
  display: block;
  width: 100%;
}

.icon--dark {
  fill: var(--c-gray-900);
  opacity: 1;
}

.icon--white {
  fill: white;
  opacity: 1;
}

.icon--spin {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.list-item .icon {
  margin-left: auto;
  margin-right: auto;
}
