#game .prompt {
  margin: 0;
  font-weight: bold;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar .button + .button {
  margin-top: 0.75rem;
}

.dealer__label {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1rem;
  margin: 0 0 0.75rem;
  text-transform: uppercase;
}

#end-screen {
  margin: 6rem auto 0;
  text-align: center;
}

#end-screen p {
  font-weight: bold;
  font-size: 1.5rem;
}

#end-screen .button + .button {
  margin-top: 1rem;
}
