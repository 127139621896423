.preview {
  border-radius: 50%;
  height: 8rem;
  margin: 0 auto;
  width: 8rem;
  overflow: hidden;
}

.preview video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
