.players h1 {
  margin: 0 0 1rem;
  font-size: 1.5rem;
  text-align: center;
}

.players p {
  margin-bottom: 1rem;
  text-align: center;
}

.selfie {
  align-items: center;
  background: var(--c-gray-200);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 3rem;
  flex: none;
  margin-left: 1rem;
  overflow: hidden;
  width: 3rem;
  -webkit-tap-highlight-color: transparent;
}

.selfie img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

#new-player {
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  margin: 1rem 0 0;
  font-size: 1rem;
  padding: 1rem;
  text-align: center;
  text-decoration: underline;
  width: 100%;
}
