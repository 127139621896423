.player-select {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  margin: 0;
  margin-left: -0.75rem;
  padding: 0;
}

.player-select--vertical {
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0;
  margin-top: -0.75rem;
}

.player-select--vertical .player-option {
  margin: 0;
  margin-top: 0.75rem;
}

.player-option {
  align-items: center;
  background: var(--c-primary-500);
  border-radius: 50%;
  box-shadow: var(--card-1);
  cursor: pointer;
  display: flex;
  flex: none;
  height: 3rem;
  justify-content: center;
  margin-left: 0.75rem;
  margin-top: 0.75rem;
  overflow: hidden;
  width: 3rem;
  -webkit-tap-highlight-color: transparent;
}

.player-option--selected {
  box-shadow: 0 0 16px yellow;
}

.player-option button {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  height: 100%;
  outline: none;
  padding: 0;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

.player-select--vertical button {
  cursor: default;
}

.player-option img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}
