.dialog[data-reach-dialog-content] {
  width: 100%;
  max-width: 375px;
}

.board {
  margin: 0;
  padding: 0;
}

.board .button {
  padding: 0.25rem;
}

.board__row {
  align-items: center;
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 0.5rem 0.75rem;
}

.board__row--header {
  font-weight: bold;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.board__delete {
  width: 2.25rem;
}

.board__name {
  margin-left: 1.5rem;
  margin-right: auto;
}

.newcomer {
  align-items: flex-end;
  display: flex;
  gap: 1.5rem;
  margin-top: 1.5rem;
  padding: 0 0.75rem;
}

.newcomer label {
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
}

.newcomer .button {
  padding: 0.25rem;
}
